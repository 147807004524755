@import url("https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700&display=swap");
@import "loading";
@import "addon";
@import "documentation";

$primary: #a9162a;
$dark: #212529;

body {
  margin: 0;
}

.login-root {
  font-family: "Inter", sans-serif;
  font-size: 0.875rem;

  background-color: #ededed;

  height: 100vh;
  width: 100vw;
}

.register-root {
  font-family: "Inter", sans-serif;
  font-size: 0.875rem;

  background-color: #ededed;

  height: 100vh;
  width: 100vw;
}

.login-card {
  background-color: white;
  max-width: 50rem;
  border-radius: 0.75rem;
  overflow: hidden;
}

.login-card-body {
  padding: 4.5rem 4rem;

  .login-card-head {
    margin-bottom: 3rem;

    .login-card-title {
      font-size: 1.5rem;
      font-weight: 700;
    }

    .login-card-subtitle {
      font-weight: 500;
      line-height: 150%;
      color: rgba($dark, 0.5);
    }
  }

  .form-label {
    font-weight: 600;
  }

  .form-control {
    padding: 0.4rem 0.6rem;
    border-width: 1.5px;

    &:focus {
      box-shadow: none !important;
      border-color: rgba(#212529, 0.5);

      &.is-invalid {
        border-color: #dc3545;
      }
    }

    &:hover {
      border-color: rgba(#212529, 0.5);
    }
  }

  .btn-submit-login {
    width: 100%;
    padding-top: 0.4rem;
    padding-bottom: 0.4rem;

    color: white;
    background-color: $primary;

    &:hover {
      background-color: #8f1324;
    }
  }

  .other-action {
    margin-top: 3.25rem;
  }

  .back-to-app {
    font-weight: 500;
    text-decoration: none;
    color: rgba($dark, 0.5);
    cursor: pointer;

    svg {
      margin-top: -2px;
    }

    &:hover {
      color: $dark;
    }
  }
}

.login-card-header {
  // background-color: $primary;
  color: white;

  padding: 3rem;

  .login-logo {
    margin-bottom: 1.5rem;
  }

  h1 {
    font-weight: 600;
    font-size: 1.5rem;
    margin-bottom: 0.25rem;
  }

  .subtitle {
    font-weight: 300;
    font-size: 1.125rem;
  }

  .login-card-decoration {
    width: 100%;
    margin-top: -3rem;
  }
}

@media only screen and (max-width: 768px) {
  .login-root {
    height: 100vh;
  }

  .login-card {
    margin: 0 1rem;
  }

  .login-card-body {
    padding: 2rem 2.5rem;
    height: 100%;

    .other-action {
      margin-top: 1.5rem;
      text-align: center;
    }

    .back-to-app {
      font-weight: 500;
      text-decoration: none;
      color: rgba($dark, 0.5);

      svg {
        margin-top: -2px;
      }

      &:hover {
        color: $dark;
      }
    }
  }

  .login-card-header {
    max-height: 90%;
    overflow: hidden;

    padding-top: 3rem !important;

    .login-logo {
      margin-bottom: 1rem;
    }

    .subtitle {
      margin-bottom: 1rem;
    }

    .login-card-decoration {
      margin-bottom: -4rem;
      margin-top: 0;
    }
  }
}

// Two-Factor Authentication

.card-2fa {
  background-color: white;
  max-width: 25rem;
  border-radius: 0.75rem;
  overflow: hidden;
}

.card-2fa-body {
  padding: 4.5rem 2rem;

  .card-2fa-illustration {
    text-align: center;
    margin-bottom: 1rem;
  }

  .card-2fa-head {
    margin-bottom: 2rem;

    .card-2fa-title {
      font-size: 1.25rem;
      font-weight: 700;
    }

    .card-2fa-subtitle {
      font-weight: 500;
      line-height: 150%;
      color: rgba($dark, 0.5);
    }
  }

  form {
    margin-bottom: 0.75rem;

    .form-control {
      padding: 0.4rem 0.6rem;
      border-width: 1.5px;

      &:focus {
        box-shadow: none !important;
        border-color: rgba(#212529, 0.5);

        &.is-invalid {
          border-color: #dc3545;
        }
      }

      &:hover {
        border-color: rgba(#212529, 0.5);
      }
    }
  }

  .card-2fa-footer {
    p {
      margin: 0;
      font-weight: 500;
      line-height: 150%;
      color: rgba($dark, 0.5);

      span {
        font-weight: 700;
        color: $dark;
        cursor: pointer;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }

  .btn-submit-login {
    width: 100%;
    padding-top: 0.4rem;
    padding-bottom: 0.4rem;

    color: white;
    background-color: $primary;

    &:hover {
      background-color: #8f1324;
    }
  }

  .other-action {
    margin-top: 3.25rem;
  }

  .back-to-app {
    font-weight: 500;
    text-decoration: none;
    color: rgba($dark, 0.5);

    svg {
      margin-top: -2px;
    }

    &:hover {
      color: $dark;
    }
  }
}

@media only screen and (max-height: 760px) {
  .register-root {
    height: auto !important;
    padding-top: 2rem !important;
    padding-bottom: 2rem;
  }

  body,
  .root {
    overflow-x: hidden;
  }
}
