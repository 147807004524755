$black: #292929;
$primary: #7366ff;
$primary-soft: #f4f3ff;

.issp-container {
  max-width: 1140px;
  margin: 0 auto;
  padding: 0 1rem;
}

#issp-nav {
  height: 4rem;
  width: 100%;

  position: fixed;
  top: 0;
  z-index: 1000;

  background-color: white;

  .issp-container {
    height: 100%;

    nav {
      height: 100%;

      .issp-brand {
        font-size: 1.25rem;
        font-weight: 500;

        color: $black;
      }
    }
  }

  .issp-avatar {
    height: 2.75rem;
    width: 2.75rem;
    margin: 3px 0;

    border: 2px solid white;
    border-radius: 50%;
    cursor: pointer;

    object-fit: cover;
  }
}

#issp-apps {
  width: 100%;
  margin-top: 4rem;
  padding: 1.5rem 0;

  .app-link {
    text-decoration: none;
  }

  .app-card {
    border: 0 !important;
    box-shadow: none !important;
    border-radius: 0.5rem !important;
    transition: all 0.2s ease-in-out;

    .card-img-top {
      aspect-ratio: 16/9;
      object-fit: cover;
      border-radius: 0.5rem 0.5rem 0 0;
    }

    .card-body {
      padding: 2rem;
    }

    .app-title {
      font-weight: 600;
      font-size: 18px;
      color: rgba(41, 41, 41, 1);
    }

    .app-description {
      font-weight: 500;
      font-size: 14px;
      line-height: 150%;
      margin-bottom: 1.25rem;
      color: rgba(41, 41, 41, 0.5);
      height: 1.3125rem;
      overflow: hidden;
    }

    .app-opd {
      margin-bottom: 0;
      font-weight: 600;
      font-size: 14px;
      color: #a9162a;
    }

    .app-role {
      padding: 4px 6px;
      font-weight: 600;
      font-size: 12.6px;
      line-height: 15px;
      border-radius: 4px;
      background-color: rgba(169, 22, 42, 0.1);
      color: #a9162a;
    }

    &:hover {
      margin-top: -0.5rem;
      margin-bottom: 0.5rem;
    }
  }
}

.issp-title {
  font-weight: 600;
  font-size: 24px;
  color: rgba(41, 41, 41, 1);
}

.issp-sub-title {
  font-weight: 450;
  font-size: 18px;
  color: rgba(41, 41, 41, 1);
  margin-bottom: 2rem;
}

.modal-body.modal-issp {
  img {
    max-width: 100%;
    object-fit: contain;
  }
}
