.issp-docs-root {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;

    display: flex;

    .docs-side {
        height: 100%;
        min-width: 240px;
        width: calc(((100vw - 1140px) / 2) + 240px);
        background-color: rgba(243, 245, 246, 1);
        border-right: 1px solid rgba(211,220,228,1.00);

        .docs-brand {
            width: 240px;
            padding: 1rem;
            margin-left: auto;
            margin-right: 0;
            margin-top: .5rem;

            img {
                width: 2.25rem;
            }

            span {
                font-weight: 700;
                font-size: 18px;
                margin-top: -.25rem;
                margin-left: .4rem;
            }
        }

        .docs-sidebar {
            width: 240px;
            padding: 1rem;
            margin-left: auto;
            margin-right: 0;

            ul {
                font-size: 16px;
                list-style: none;
                padding: 0;
                padding-top: .25rem;
                padding-bottom: 1rem;

                .docs-title {
                    font-size: 14px;
                    text-transform: uppercase;
                    font-weight: 500;
                    color: rgba(136,153,168,1.00);
                    letter-spacing: 1px;
                }

                li {
                    padding-top: .4rem;
                    padding-bottom: .4rem;
                    a {
                        color: rgba(92, 105, 117, 1);
                        font-weight: 500;
                        text-decoration: none;

                        &:hover {
                            color: #a9162a!important;
                        }
                    }

                    &.active {
                        a {
                            font-weight: 500;
                            color: #a9162a!important;
                        }
                    }
                }
            }
        }
    }

    .docs-main {
        height: 100%;
        flex-grow: 1;
        overflow-y: auto;

        .docs-brand {
            display: none;
        }

        .docs-content {
            max-width: 880px;
            padding: 1.25rem 2.5rem;

            .content-container {
                h1 {
                    font-size: 2rem;
                }

                h2 {
                    font-size: 1.5rem;
                }

                a {
                    text-decoration: none;
                }

                .api-path {
                    font-family: SFMono-Regular,Menlo,Monaco,Consolas,"Liberation Mono","Courier New",monospace;
                    padding: .5rem 1rem;
                    border-radius: 4px;
                    background-color: rgba(243, 245, 246, 1);
                    border: 1px solid rgba(211,220,228,1.00);
                    overflow-wrap: break-word;
                }

                thead tr th {
                    font-weight: 500!important;
                }
            }
        }
    }
}

@media (max-width: 660px) {
    .issp-docs-root {
        .docs-side {
            display: none;
        }

        .docs-main {
            .docs-brand {
                display: block;
                max-width: 880px;
                padding: 1rem 1rem;

                img {
                    width: 2.25rem;
                }

                span {
                    font-weight: 700;
                    font-size: 18px;
                    margin-top: -.25rem;
                    margin-left: .4rem;
                }
            }

            .docs-content {
                padding: .5rem 1rem;
            }
        }
    }
}