.modal.fade {
    font-family: system-ui,-apple-system,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans","Liberation Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji"!important;

    .form-label {
        font-weight: 500!important;
    }
}

.dropdown-menu {
    border: none!important;
    box-shadow: 0 0.15rem 1.75rem 0 rgba(31, 45, 65, .15)!important;
}